import React, { useEffect, useState } from 'react';
import { usePrvtAPI } from '../../PrivateProvider/PrivateProvider'
//import { Refresh } from '../Invest/Pool/PoolIntel/Functions/refresher'

//Style
import fennec_logo from '../../../content/FennecTitle.png'
import metamask_fox_logo from '../../../content/MetaMask_Fox.png'
import './Dashboard.css'

//WEB3 Ethers
import { ethers } from "ethers";

//Import USDT
import { USDT_abi } from '../Invest/Pool/PoolIntel/Constants/USDT_abi.js'
import { USDT_Polygon_Adrs } from '../Invest/Pool/PoolIntel/Constants/USDT'

import axios from "axios";
import { sendTxnUSDC } from "../Invest/Pool/PoolIntel/Functions/sendTxnUSDC";

//import positions table
import {TablePos} from './positions/positions_table';

const BASE_URL = process.env.REACT_APP_BASE_URL_BACK;

function Dashboard() {

    const { privateData } = usePrvtAPI();

    let [pending, setPending] = useState(0);
    let [locked, setLocked] = useState(0);

    let [submitable, setSubmitable] = useState(true);



    //Validate user's available positions
    useEffect(()=>{
        
        async function verifyPending(){


            await axios({
                method: 'post',
                url: `${BASE_URL}/api/confirmUSDCRCVD`,
                data: {
                    userID: privateData._id
                }
            });


            let sumpends = await axios({
                method: 'post',
                url: `${BASE_URL}/api/sumpendings`,
                data: {
                    userID: privateData._id
                }
            });

            //console.log(sumpends)
            setPending(sumpends.data.resu.sumpendings);
        
        };
        
        //ACTIVATE THIS AND THE ELSE CONDITION TO HAVE DASHBOARD RELOAD PENDING CHECK.
        //let theInt = setInterval(()=>{

        if(privateData){

            async function sumLocked(){
                let sumpos = await axios({
                    method: 'post',
                    url: `${BASE_URL}/api/sumpositions`,
                    data: {
                        userID: privateData._id
                    }
                });
    
                setLocked(sumpos.data.resu.sumpositions)
            }
            sumLocked();


            if(privateData.Pending.length > 0){
                verifyPending();
            }else{
                //clearInterval(theInt);
                //console.log("Interval cleared!")
            }
        }



    }, [privateData])


    let [accountAddress, setAccountAddress] = useState("");
    let [isConnected, setIsConnected] = useState(false);

    let [balance, setBalance] = useState(0);
    let [usdt, setUSDC] = useState(0);

    const chainId = 137 // Polygon Mainnet

    async function connectWallet() {
        try {
            if (!window.ethereum) {
                //console.log("download MM!");
                if (window.confirm("You don't seem to have Meta Mask installed, add it as an extension !")) {
                    window.location.href='https://metamask.io/';
                };
            }

            if (window.ethereum.networkVersion !== chainId) {
                switchNetwork();
            }else{
                const accounts = await window.ethereum.request({
                    method: 'eth_requestAccounts',
                });
    
                setAccountAddress(accounts[0]);
                getBalance(accounts[0]);
                setIsConnected(true);
            }

        } catch (error) {
            console.log(error);
        }
    };

    //console.log(isConnected)

    function disconnectWallet(){
        setAccountAddress("");
        setIsConnected(false);
        setBalance(0);
        setUSDC(0);
    }

    async function switchNetwork(){

        try {

            const networkMap = {
                POLYGON_MAINNET: {
                  chainId: ethers.utils.hexValue(137), // '0x89'
                  chainName: "Polygon Mainnet", 
                  nativeCurrency: { name: "MATIC", symbol: "MATIC", decimals: 18 },
                  rpcUrls: ["https://polygon-rpc.com"],
                  blockExplorerUrls: ["https://polygonscan.com"],
                },
                MUMBAI_TESTNET: {
                  chainId: ethers.utils.hexValue(80001), // '0x13881'
                  chainName: "Mumbai Testnet",
                  nativeCurrency: { name: "tMATIC", symbol: "tMATIC", decimals: 18 },
                  rpcUrls: ["https://rpc-mumbai.maticvigil.com"],
                  blockExplorerUrls: ["https://mumbai.polygonscan.com"],
                },
              };

            await window.ethereum.request({
                method: "wallet_addEthereumChain",
                params: [networkMap.POLYGON_MAINNET],
            });

            const accounts = await window.ethereum.request({
                method: 'eth_requestAccounts',
            });

            setAccountAddress(accounts[0]);
            getBalance(accounts[0]);
            setIsConnected(true);

        } catch (err) {
            console.log(err)
        }
        
    }


    async function getBalance(wallet) {
        const provider = new ethers.providers.Web3Provider(window.ethereum, "any");
        //const signer = provider.getSigner();
        let balance = await provider.getBalance(wallet);
        // we use the code below to convert the balance from wei to eth
        let Matic_bala = ethers.utils.formatEther(balance);

        setBalance(Matic_bala);

        const usdcContract = new ethers.Contract(USDT_Polygon_Adrs, USDT_abi, provider);
        //const tokenName = await usdcContract.name();
        const tokenBalance = await usdcContract.balanceOf(wallet);
        const tokenUnits = await usdcContract.decimals();
        const balanceInEther = ethers.utils.formatUnits(tokenBalance, tokenUnits);

        setUSDC(balanceInEther);
    };


    //////////////////////////////////////////////// TXN

    const handleSubmit = async (e) => {

        e.preventDefault();


        if( submitable === true ){

            setSubmitable(false);
            //if user rejected txn then set interval to re-submitable:
            //console.log("timer staerted...")
            window.setInterval(function(){
                //  3 seconds for re-sumbitable
                setSubmitable(true);
                console.log("submitable");
            }, 4000);

            const data = new FormData(e.target);
            let amountUSDC = data.get("USDT");
    
            if(parseFloat(amountUSDC) > parseFloat(usdt)){
    
                alert("You have insufficient funds!");;
    
            }else if(balance < 0.01){
    
                alert("You might need more MATIC for gas!");
    
            }else if(amountUSDC <= 0 | amountUSDC.includes('e') ){
    
                alert("Deposit a valid amount of USDT!");
    
            }else{
                console.log("User wishes to deposit:", amountUSDC, " USDT!");
                let trxn_hash = await sendTxnUSDC(accountAddress, amountUSDC);
                
                let txn_confirmed = await axios({
                    method: 'post',
                    url: `${BASE_URL}/api/registerUSDCTXN`,
                    data: {
                        TransactionHash: trxn_hash,
                        userID: privateData._id,
                        Inv_Capital: amountUSDC,
                        SenderWallet: accountAddress
                    }
                });
    
    
                if(txn_confirmed.data.resu.status === "1"){
    
                    alert("Transaction Sent! \n This could take 10-15 mins, so get a coffe!  :)");
                    setSubmitable(true);
    
                    let checks = 0;
    
                    function checkChecks() {
                        if (checks === 10){
                            clearInterval(intervalId1);
                        }
                      }
    
                    var intervalId1 = window.setInterval(function(){
                        // call your function here
                        getBalance(accountAddress);
                        checks += 1
                        checkChecks();
                    }, 2000);
    
                }else{
                    console.log("Transaction Failed! :(");
                    setSubmitable(true);
                }
            }


        }
    }

    function capitalizeFirstLetter(str) {
        if(str){
            // converting first letter to uppercase
            const capitalized = str.charAt(0).toUpperCase() + str.slice(1);
            return capitalized;
        }else{
            return
        }
    }

    let value =  privateData.Available + locked;

    return (

        <div className='Dashboard'>
            <br />

            <div className='logo-middle'>
                <img src={fennec_logo} alt="cg logo" width="200" />
            </div>

            <div className='connectIntel'>

                { 
                    isConnected === false && 
                    <>
                        <button className="polygon-butt compu" onClick={ ()=> connectWallet()}> <img src={metamask_fox_logo} alt="cg logo" width="25"/> Connect Wallet </button>
                        <button className="polygon-butt phone" onClick={ ()=> connectWallet()}>  Connect </button>
                    </>
                }
                {
                    isConnected === true &&
                    <>
                        <button className="polygon-butt compu" onClick={ ()=> disconnectWallet()} > <img src={metamask_fox_logo} alt="cg logo" width="25"/> Disconnect Wallet</button>
                        <button className="polygon-butt phone" onClick={ ()=> disconnectWallet()} >  Disconnect</button>

                    </>
                }

                <br />

                {
                    isConnected &&  
                    <div className='submitUSDC' >                   
                        <form onSubmit={handleSubmit}>

                            <input 
                                name="USDT"
                                type="number"
                                placeholder="Amount in USDT"
                            />           

                            <button type="submit"> Deposit !</button>
        
                        </form>
                    </div>
                }

                {
                    isConnected === true && 
                    <div className='con_wallet_info'>
                        <p>Wallet: {accountAddress.slice(0, 4)}...{accountAddress.substring(accountAddress.length - 4)}</p>
                        <p>For Gas: {parseFloat(balance).toFixed(2)}</p>
                        <p>USDT: ${parseFloat(usdt).toFixed(2)}</p>
                    </div>
                } 

            </div>


            
            <div className='intro_dashboard'>

                <h2 className='username'>Hey {capitalizeFirstLetter(privateData.username)} 👋</h2>
                
                { value <=0 &&
                    <>
                    <h3>Welcome to your Fennec dashboard.</h3>
                    <p className='tuto'>Connect your wallet and deposit some USDT !</p>
                    <p className='tuto'>If you don't have USDT, or hold other coins: <a style={{color: '#67499e'}} href="https://wallet.polygon.technology/polygon/token-swap" target="_blank" rel="noopener noreferrer">swap to USDT</a>.</p>
                    </>
                }

            </div>


            <div className='userbalances'>
                
                <div className='available'>
                    <h3>
                        Available
                    </h3>
                    <p>
                        {privateData.Available>=0 &&<p>${parseFloat(privateData.Available).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</p>}
                        {isNaN(privateData.Available) &&<p>${parseFloat(0.00).toFixed(2)}</p>}
                    </p>
                </div>


                <div className='locked'>
                    <h3>
                        Locked
                    </h3>
                    <p>

                        {locked>=0 &&<p>${parseFloat(locked).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</p>}
                        {isNaN(locked) &&<p>${parseFloat(0.00).toFixed(2)}</p>}
                    </p>
                </div>


                {privateData.Withdrawal > 0 && 
                    <div className='withdrawing'>
                        <h3>
                            Withdrawing
                        </h3>
                        <p>
                            {privateData.Withdrawal> 0 && <p>${parseFloat(privateData.Withdrawal).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</p>}
                        </p>
                    </div>
                }


                {pending > 0  &&
                    <div className='pending'>
                        <h3>
                            Pending
                        </h3>
                        <p>
                            {pending>0 && <p>${parseFloat(pending).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</p>}
                        </p>
                    </div>
                }



            </div>

            <div className='userPositions'>

            {
             privateData.Positions &&
             <>
             
                <TablePos/>
                
            </>
            }


            </div>
            
        </div>
    );
}

export default Dashboard;