import React, { useEffect, useState } from 'react';
import { usePrvtAPI } from '../../PrivateProvider/PrivateProvider'
import { useHistory } from "react-router-dom";

import fennec_logo from '../../../content/FennecTitle.png'
import './withdrawal.css'

import axios from "axios";


const BASE_URL = process.env.REACT_APP_BASE_URL_BACK;


function Withdrawal() {

    const { privateData } = usePrvtAPI();

    let [canWithdraw, setCanWithdrwa] = useState(false);

    let history = useHistory();

    const logoutHandler = () => {
      localStorage.removeItem("authToken");
      history.push("/dashboard")
    }

    useEffect(()=>{

        if(privateData.Available){
            setCanWithdrwa(true);
        }else{
            setCanWithdrwa(false);
        }

    },[privateData])


    //////////////////////////////////////////////// TXN
    const handleSubmit = async (e) => {
        e.preventDefault();

        const data = new FormData(e.target);
        let amountUSDC = data.get("USDT");

        if(parseFloat(amountUSDC) > parseFloat(privateData.Available)){

            alert("You have insufficient Available $ to withdraw !");;

        /*}else if(amountUSDC <= 1){

            alert("You need at least 1 USDT!");

        */}else{
            //console.log("User wishes to withdraw:", amountUSDC, " USDT!");
            
            let txn_confirmed = await axios({
                method: 'post',
                url: `${BASE_URL}/api/withdrawUSDC`,
                data: {
                    userID: privateData._id,
                    Remove_Capital: amountUSDC,
                }
            });

            if(txn_confirmed.data.resu.status === "1"){
                alert("Your money will be sent to your Web3 wallet shortly!");


            }else{alert("WITHDRAWL FAILED!")}
        }
    }



    function capitalizeFirstLetter(str) {
        if(str){
            // converting first letter to uppercase
            const capitalized = str.charAt(0).toUpperCase() + str.slice(1);
            return capitalized;
        }else{
            return
        }
    }



    
    return (
        <div className="Settings">
            <br />
            <div className='navbar'>
             <img src={fennec_logo} alt="cg logo" width="200"/>
            </div>

            <div className='balances-left'>
                <div className='userbalancez'>
                    {privateData.Available>=0 &&<p>Available: ${parseFloat(privateData.Available).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</p>}
                    {isNaN(privateData.Available) &&<p>Available: ${parseFloat(0.00).toFixed(2)}</p>}
                    {/*privateData.Withdrawal>=0 && <p>Withdrawing: ${parseFloat(privateData.Withdrawal).toFixed(2)}</p>*/}

                </div>
            </div>

            <div className='intel_settings'>

                <h2>Untill next time, {capitalizeFirstLetter(privateData.username)}.</h2>


                {
                    canWithdraw &&  
                    <div>                   
                        <form onSubmit={handleSubmit}>

                            <input
                                name="USDT"
                                type="number"
                                placeholder="Amount in USDT"
                            />           

                            <button type="submit"> Withdraw !</button>
        
                        </form>
                    </div>
                }



                <br />
                <br />
                <button className='logout' onClick={logoutHandler}>Logout</button>

            </div>
            
        </div>
    );
}

export default Withdrawal;