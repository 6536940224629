import React, {useState, useEffect} from "react";
import './Pool.css'
import axios from "axios";

import { useFennecIntelAPI } from "./PoolIntel/fennecIntel";

import { usePrvtAPI } from "../../../PrivateProvider/PrivateProvider";

const BASE_URL = process.env.REACT_APP_BASE_URL_BACK;

function Pool1Row() {

    let { fennecIntel }  = useFennecIntelAPI();
    //console.log(fennecIntel)
    let [fennecMax, setFennecMax] = useState();
    let { privateData } = usePrvtAPI();
    let [investable, setInvestable] = useState(false);
    let [PrettierDate, setPrettierDate] = useState();


    //////////////////////////////////////////////// RATES    
    useEffect(()=>{

        if(fennecIntel){

            setFennecMax(fennecIntel.data.result.fennecMax1);

            if(fennecMax && isNaN(fennecMax.anual) === false  && privateData.Available >= 10 && fennecMax.Difference >= 15){
                setInvestable(true);
            }

        }

    }, [fennecIntel, privateData.Available])

    //console.log(fennecMax)

    //////////////////////////////////////////////// TXN
    const handleSubmit = async (e) => {

        //ONLY GENERATE POSITION IF AVIALABLE
        e.preventDefault();
        const data = new FormData(e.target);
        let amount_invest = data.get("USDT");

        /*if(parseFloat(amount_invest) > parseFloat(privateData.Available)){
            alert("You have insufficient funds, go add some funds !");
        }else */if (isNaN(parseFloat(amount_invest)) || parseFloat(amount_invest) < 0){
            alert("Add a valid amount!");
        }/*else if (parseFloat(amount_invest) < 10){
            alert("We would recomend to invest at least $10.");
        }*/else{
            const reqData = {
                userID: privateData._id,
                Inv_Capital: amount_invest,
                Fut_Symbol: fennecMax.Fut_Symbol,
                Fut_bid_price: fennecMax.Fut_bid_price,
                Spot_ask_price: fennecMax.Spot_ask_price,
                anual: fennecMax.anual, 
                dir: fennecMax.dir,
                Maturity: fennecMax.Maturity,
                Difference: fennecMax.Difference,
            };
            console.log("User wishes to invest:", amount_invest, " USDT!");

            //GENERATE POSITION IN THE OKX MRKT
            let position_opened = await axios({
                method: 'post',
                url: `${BASE_URL}/api/generatePosition`,
                data: reqData,
                header:{
                    "Content-Type": "application/json"
                },
            });
            //console.log(position_opened);
            if(position_opened.data.resu.status === "1"){
                alert("Position opened !")

                //REGISTER POSITIONS (Available balance has already been adjusted)
                let positions_adjust = await axios({
                    method: 'post',
                    url: `${BASE_URL}/api/registerPosition`,
                    data: reqData,
                    headers:{
                        "Content-Type": "application/json"        
                    },
                });
                
                console.log("Your adjusted positions: ", positions_adjust);

            }else if(position_opened.data.resu.status === "2"){
                alert("We recomend to round up to the nearest $10. To get full coverage.")
            }else if(position_opened.data.resu.status === "3"){
                alert("We recomend to invest at least $100 for this position.")
            }else if(position_opened.data.resu.status === "0"){
                alert("Position could not be opened; dont worry, available fund's where not affected, try again!")
            }

        }
    };


    useEffect(()=>{
        if(fennecMax){
            function convertdate(str){
                let arr = str.split("/");
                let new_date = arr[2]+"/"+arr[0]+"/"+arr[1];
                setPrettierDate(new_date);
            };
            convertdate(fennecMax.Maturity);
        }
    }, [fennecMax])


    if(fennecMax){
        return (
            <tbody style={{ width: "100%"}}>

                <tr>
                    <td style={{ width: "5%"}}>{PrettierDate}</td>
                    <td style={{ width: "20%"}}>{fennecMax.Difference}</td>
                    <td style={{ width: "20%"}}>{parseFloat(fennecMax.dir).toFixed(2)}%</td>
                    <td style={{ width: "20%"}}>{parseFloat(fennecMax.anual).toFixed(2)}%</td>
                    <td style={{ width: "20%"}}> 

                    {
                            investable &&  
                            <div>                   
                                <form className="investForm" onSubmit={handleSubmit}>
    
                                    <input className="inputbox"
                                        name="USDT"
                                        type="number"
                                        placeholder="USD Amount"
                                    />           
    
                                    <button className="inputbutton" 
                                    type="submit"> Invest !</button>
            
                                </form>
                            </div>
                        }
                        {
                            ! investable &&
                            <div className="warning">                   
                                <p>...</p>
                            </div>
                        }
                    
                    </td>
                </tr>
            
            </tbody>
        );
    }else{
        return(<tr></tr>);
    }
}

export default Pool1Row;