import React, { useState, useEffect, createContext, useContext } from "react";
const axios = require('axios');

const BASE_URL = process.env.REACT_APP_BASE_URL_BACK;

export const FennecIntelContext = createContext(null);

export function FennecIntelProvider({children}) {

    let [fennecIntel, setFennecIntel] = useState(null);

    //Getting from the back end the FENNEC INTEL every 1 SECONDS
    useEffect(()=>{
        setInterval(function() {
            axios.get(`${BASE_URL}/api/fennecIntel`)
            .then(function(result) {
                //console.log(result)
                setFennecIntel(result);
            });
        }, 1000); // time for each request !
    }, [])



    return (
        <FennecIntelContext.Provider value={{ fennecIntel }}>
            {children}
        </FennecIntelContext.Provider>
    );

};

export function useFennecIntelAPI() {
    const context = useContext(FennecIntelContext);
    if (context === undefined) {
      throw new Error("Context must be used within a Provider");
    }
    return context;
}