import React from 'react';

import {Board} from '../Screens/Board'
import {APIContextProvider} from './PrivateProvider'

import { slide as Menu } from 'react-burger-menu';
import './sidebar.css';

//Import Intel providers of the POOL for the whole dashboard to use as context!
import { FennecIntelProvider } from '../Screens/Invest/Pool/PoolIntel/fennecIntel';

export function PrivateScreen () {

  return (
      <APIContextProvider>

        <FennecIntelProvider>


          <Menu >
            <a id="dashboard" className="v dashboard" href="/dashboard" style={{"textDecoration":'none',"color":'#b8b7ad'}}><h4>Dashboard</h4></a>
            <a id="invest" className="menu-item invest" href="/invest" style={{"textDecoration":'none',"color":'#b8b7ad'}}><h4>Invest</h4></a>
            <a id="withdrawal" className="menu-item withdrawal" href="/withdrawal" style={{"textDecoration":'none',"color":'#b8b7ad'}}><h4>Withdrawal</h4></a>
          </Menu >
          
          <Board/>


        </FennecIntelProvider>


      </APIContextProvider>
  );
};