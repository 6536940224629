import React from 'react';
import { useHistory } from "react-router-dom";

import './header.css'

export function Header() {

    const history = useHistory();
    
    async function handleLogin(){
        history.push("/login");
    }
    async function handleRegister(){
        history.push("/register");
    }
   


    const visibility = 'hidden'; //remaind from another functionality..



    // If show is true this will be returned
    return (
        <div className={visibility}>

            <div className='Header'>


                <div className='launchapp'>

                    <button onClick={ handleLogin } className="button-48 compu">
                        <span className="textbutt">
                            <h4>Login</h4>  
                        </span>
                    </button> 

                    <button onClick={ handleRegister } className="button-48 compu">
                        <span className="textbutt">
                                <h4>Register</h4> 
                        </span>
                    </button> 

                    {/* ON SMALL SCREEN ONLY ONE */}
                    <button onClick={ handleLogin } className="button-48 phone">
                        <span className="textbutt">
                                <h4>Launch APP !</h4> 
                        </span>
                    </button> 

                </div>
            </div>

        </div>
    )




}