import React from 'react';
import { usePrvtAPI } from '../../PrivateProvider/PrivateProvider'

//import Pool1 from './Pool/Pool1';
//import Pool2 from './Pool/Pool2';
import DualPoolTable from './Pool/DualPoolTable';

import fennec_logo from '../../../content/FennecTitle.png'
import './Invest.css'


export default function Invest() {

    const { privateData } = usePrvtAPI();

    //const [visible, setVisible] = useState('dual');
    const visible = 'dual'
    /*async function handleToggle(){
        if(visible === 'long'){setVisible('short')}
        else if(visible === 'short'){setVisible('dual')}
        else{setVisible('long')}
    }*/

    return (
        <>
        <div className="Invest">
            <br />
            
            <div className='logo-middle'>
                <img src={fennec_logo} alt="cg logo" width="200"/>
            </div>


            <div className='balances-left'>
                <div className='userbalancez'>
                    {privateData.Available>=0 &&<p>Available: ${parseFloat(privateData.Available).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</p>}
                    {isNaN(privateData.Available) &&<p>Available: ${parseFloat(0.00).toFixed(2)}</p>}
                </div>
            </div>



            {
                visible === 'dual' &&
                <>
                    <DualPoolTable />
                </>                 

            }




        </div>

        

    </>
    );
}