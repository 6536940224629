import React, { useState, useEffect } from 'react';

import { usePrvtAPI } from '../../../PrivateProvider/PrivateProvider';

import axios from "axios";

import './positions_table.css'

const BASE_URL = process.env.REACT_APP_BASE_URL_BACK;


export function TablePos () {

    const { privateData } = usePrvtAPI();

    let [positions, setPositions] = useState([]);
    let [differenceArray, setDifference] = useState([]);

    let [visible, setVisible] = useState(false);


    useEffect(()=>{

        async function verifyPositions(){

            let positionsids = privateData.Positions.map((positionsID)=>{
                return positionsID
            });

            if(positionsids.length > 0){

                let allpos = await axios({
                    method: 'post',
                    url: `${BASE_URL}/api/allpositions`,
                    data: {
                        userID: privateData._id
                    }
                });

                setPositions(allpos.data.resu.allposs);

                let difference = positions.map(pos => {
                    let Mat = pos.Maturity;
                    var maturity = new Date(Mat);

                    var today = new Date();
                    var dd = String(today.getDate()).padStart(2, '0');
                    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January = 0!
                    var yyyy = today.getFullYear();
                    today = new Date(`${mm + '/' + dd + '/' + yyyy}`);    

                    var diff = (maturity.getTime() - today.getTime())/(24*3600*1000);
                    
                    return diff
                });

                setDifference(difference);

            }
        };

        if(privateData){
            
            if(differenceArray.length === 0) {
                verifyPositions();
            }
        }

    }, [privateData]);



    async function seePositions(){

        if(visible === false){
            setVisible(true);
        }else if(visible === true){
            setVisible(false)
        }
    
    }
    

    return (
        <div>

            {differenceArray.length > 0 && 
            <>
                
                <div className='positions'>
                    <table style={{ width: "100%"}}>

                        <thead>
                            <tr>
                                <th>
                                    {!visible && <button  className='seepositions' onClick={ ()=> seePositions()}>&#x25B6; </button>}
                                    {visible && <button className='seepositions' onClick={ ()=> seePositions()}>&#x25BC; </button>}
                                </th>
                                <th>Capital</th>
                                <th>Direct Rate</th>
                                <th>Maturity</th>
                                <th>Days left</th>
                                <th>APY</th>
                            </tr>
                        </thead>

                        {visible && 
                            <tbody style={{ width: "100%"}}>
                                {positions.length > 0 && positions.map((val, key) => {
                                    return (
                                        <tr style={{ width: "100%"}} className='rowintable' key={key}>
                                            <td style={{ width: "3%"}}></td>
                                            <td style={{ width: "20%"}}>${val.Capital}</td>
                                            <td style={{ width: "20%"}}>{val.dir.toFixed(2)}%</td>
                                            <td style={{ width: "20%"}}>{val.Maturity.split('T')[0].replaceAll("-", "/")}</td>
                                            <td style={{ width: "20%"}}>{Math.round(differenceArray[key], 2)}</td>
                                            <td style={{ width: "20%"}}>{val.apy.toFixed(2)}%</td>
                                        </tr>
                                    )
                                })}

                            </tbody>
                        }


                    </table>

                </div>

            </>

            }
            
        </div>
    );
};