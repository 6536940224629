import React from 'react';
import './landingpage.css';
import { Landing } from './newlanding/page'

/*
import { Header } from './bars/Header'

import { Arrival }from './centercontent/Arrival'
import { Intro }from './centercontent/Intro'
import { LandingFacts } from './centercontent/LandingFacts'
import { BackersnTeam } from './centercontent/backersnteam'

import { Footer } from './bars/Footer'
*/


function LandingPage() {

    return (

        <div className='Landing'>

            <Landing/>

        </div>
    );
}

export default LandingPage;