import React, { useState, useEffect, createContext, useContext } from "react";
import axios from "axios";

const BASE_URL = process.env.REACT_APP_BASE_URL_BACK;

export const PrivateAPIContext = createContext();

export function APIContextProvider ({children}) {
    const [error, setError] = useState("");
    const [privateData, setPrivateData] = useState("");
    const [loading, setLoading] = useState(true);

  
    useEffect(() => {

      setInterval(function() {

        const fetchPrivateData = async () => {
          const config = {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("authToken")}`,
            },
          };
    
          try {
            const { data } = await axios.get(`${BASE_URL}/api/private`, config);
            setPrivateData(data.data);
            setLoading(false)
          } catch (error) {
            localStorage.removeItem("authToken");
            setError("You are not authorized, please click here to login.");
          }
        };

        
        
        fetchPrivateData();

      }, 1000); // time for each request !

      
  
    }, []);




    return  error ? (
      <>      

      <a href="/login" style={{display: 'flex',  justifyContent:'center', alignItems:'center', height: '100vh'}} >
        <span className="error-message">{error}</span>
      </a>

      </>
    ) : (
      <PrivateAPIContext.Provider value={{privateData, loading}}>
        {children}
      </PrivateAPIContext.Provider>
    );
}


export function usePrvtAPI() {
    const context = useContext(PrivateAPIContext);
    if (context === undefined) {
      throw new Error("Context must be used within a Provider");
    }
    return context;
}