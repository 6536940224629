import React from "react";

import Pool1Row from './Pool1Row';
import Pool2Row from './Pool2Row';

import './DualPoolTable.css'

function DualPoolTable(){
    return(
        <div className="investpooltable">
            <table  style={{ width: "100%"}} >

                <thead>
                    <tr>
                            
                        <th  style={{ width: "20%"}} className='investheads'>Maturity: </th>
                        <th  style={{ width: "20%"}} className='investheads'>Lockup Days: </th>
                        <th  style={{ width: "20%"}} className='investheads'>Direct Rate: </th>
                        <th  style={{ width: "20%"}} className='investheads'>APY: </th>
                        <th  style={{ width: "20%"}} className='investheads'>Invest!</th>

                    </tr>
                </thead>

                <Pool1Row />
                <Pool2Row />


            </table>

        </div>
    )
}

export default DualPoolTable;
