import React, {useState, useEffect} from "react";
import { Header } from './header'
import axios from "axios";


import './page.css'
import FennecTitle from '../../../content/FennecTitle.png'
import StarThing from '../../../content/StarThing.png'
import Arrow from '../../../content/Arrow.png'


export function Landing() {

    const BASE_URL = process.env.REACT_APP_BASE_URL_BACK;

    let [fennecIntel, setFennecIntel] = useState(null);
    let [fennecMax, setFennecMax] = useState();

    //Getting from the back end the FENNEC INTEL every 1 SECONDS
    useEffect(()=>{

        setInterval(function() {
            //console.log(`Request sent to: ${BASE_URL}/api/fennecIntel`)

            axios.get(`${BASE_URL}/api/fennecIntel`)
            .then(function(result) {
                //console.log(result.data.result)
                setFennecIntel(result.data.result);
            });
        }, 3000);//sec
    }, [])

    //////////////////////////////////////////////// RATES    
    useEffect(()=>{

        if(fennecIntel){

            let anual1 = fennecIntel.fennecMax1.anual;
            let anual2 = fennecIntel.fennecMax2.anual;

            let arr = [anual1, anual2]

            let maxxx = Math.max(...arr);
            //console.log(maxxx)

            setFennecMax(maxxx);

        }

    }, [fennecIntel])




    const openInNewTab = url => {
        window.open(url, '_blank', 'noopener,noreferrer');
    };

    return (
        <div className='landingNew'>
            
            <div className='whiteborder'>

                <div className='gradient1'></div>

                <div className='block0'>

                    <Header/>

                </div>

                <div className='block1'>

                    <div className='starshit'> 
                        <img src={StarThing} className='starthing' alt="start"/>
                    </div>

                    <div className='intelshit'>

                        <img src={FennecTitle} className='fennectitle' alt="logo"/>
                        <h1 className='slogan'>Risk Averse Yield for Stablecoins.</h1>

                        <div className='subtitles'>
                            <img src={Arrow} className='arrow' alt="arrow1"/>
                            <p className='subtitleText'>The Interest Rate chosen by Web3 Traders</p>
                        </div>

                    </div>

                </div>

                <div className='block2'>

                    {
                        fennecMax && 
                        <div className="borderapy">
                            <div className='centerAPY'>
                                <h3>
                                    Current APY
                                </h3>
                
                                <h2 className="theapy">  {parseFloat(fennecMax).toFixed(2)}% </h2>
                            </div>  
                        </div>
                    }
                    {
                        !fennecMax && 
                        <div className="borderapy">
                            <div className='centerAPY'>
                                <h3>
                                    Current APY
                                </h3>
                
                                <h2 className="theapy"> ... </h2>
                            </div>  
                        </div>
                    }
                    <div className='gradient3'></div>

                </div>

                <div className='block3'>
                    <div className='docs'>
                        <h3>Documentation</h3>
                        <div className='links'>
                            <button className='link'
                                onClick={() => openInNewTab('https://docs.google.com/document/d/111_W4lQ7gAvaYzZA0BGso5Y-8_yO5fZZ/edit?usp=sharing&ouid=106278247833064174692&rtpof=true&sd=true')}>
                                    Whitepaper
                            </button>
                            <button className='link'
                                onClick={() => openInNewTab('https://docs.google.com/document/d/1i2qEAss0yELtLtpQ7qaKRG6Zaye5CFxr/edit?usp=sharing&ouid=106278247833064174692&rtpof=true&sd=true')}>
                                    User Guide
                            </button>
                        </div>
                    </div>
                    
                    <div className='socials'>
                        <h3>Community</h3>
                        <div className='links'>
                        <button className='link'
                            onClick={() => openInNewTab('https://twitter.com/FennecFinance')}>
                                Twitter
                        </button>
                        <button className='link'
                        onClick={() => openInNewTab('https://discord.gg/69P5qMWdmx')}>
                            Discord
                        </button>
                        </div>

                    </div>
                </div>


                <div className='gradient2'></div>

            </div>

        </div>
    );
}

