import React from 'react';

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

// Routing
import PrivateRoute from "./components/routing/PrivateRoute";

// Screens
import { PrivateScreen } from "./components/pages/PrivateProvider/PrivateShi";

import LoginScreen from "./components/pages/LoginScreens/LoginScreen";
import RegisterScreen from "./components/pages/LoginScreens/RegisterScreen";
import ForgotPasswordScreen from "./components/pages/LoginScreens/ForgotPasswordScreen";
import ResetPasswordScreen from "./components/pages/LoginScreens/ResetPasswordScreen";
import LandingPage from "./components/pages/LangingPage/LandingPage";

const App = () => {
  return (
    <Router>
      <div className="app">
        <Switch>
          <Route exact path="/" component={LandingPage} />
          <Route exact path="/login" component={LoginScreen} />
          <Route exact path="/register" component={RegisterScreen} />
          <Route
            exact
            path="/forgotpassword"
            component={ForgotPasswordScreen}
          />
          <Route
            exact
            path="/passwordreset/:resetToken"
            component={ResetPasswordScreen}
          />

          <PrivateRoute exact path="/dashboard" component={PrivateScreen} />
          <PrivateRoute exact path="/invest" component={PrivateScreen} />
          <PrivateRoute exact path="/withdrawal" component={PrivateScreen} />

        </Switch>
      </div>
    </Router>
  );
};

export default App;
