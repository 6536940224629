import { ethers } from "ethers";
//import { USDC_Polygon_Adrs } from "../Constants/USDC";
//import { USDC_abi } from "../Constants/USDC_abi";

import { USDT_Polygon_Adrs } from "../Constants/USDT";
import { USDT_abi } from "../Constants/USDT_abi";

export async function sendTxnUSDC(fromadrs, amount_usdc){

    const provider = new ethers.providers.Web3Provider(window.ethereum, "any");
    const signer = provider.getSigner();
    
    //const usdt = new ethers.Contract(USDT_Polygon_Adrs, USDT_abi, signer);
    //console.log("congrates ! Your USDT contract has been called properly!");
    //console.log(usdt);

    const usdt = new ethers.Contract(USDT_Polygon_Adrs, USDT_abi, signer);

    let to = "0x0c256b7b7d7c94b7f561b33f507d45b5924f1bb1"; //OKX

    let toAdrs = ethers.utils.getAddress(to);
    let value = amount_usdc;

    if(value < 0){
        throw new Error();
    }

    const balance = await usdt.balanceOf(fromadrs)
    const tokenUnits = await usdt.decimals();
    const balanceInUsdc = ethers.utils.formatUnits(balance, tokenUnits);


    if(Number(balanceInUsdc) < Number(value)) {
        console.error(
            `Insuficient balance to send ${value} (you have ${balanceInUsdc})`
        );
    }

    console.log("value to send : ", String(value));

    const tx = await usdt.transfer(toAdrs, ethers.utils.parseUnits(String(value), tokenUnits))
    console.log("Transaction hash: ", tx.hash);
    /* THIS TAKES A WHILE LONGER.. not sure if needed (NOW THAT WE VALIDATE WITH OKX AND NOT POLYGONSCAN)
    const recipt = await tx.wait();
    console.log("Transaction confirmed in block:", recipt.blockNumber);
    console.log("Type of txn hash: ",typeof(tx.hash));
    */
    //tx.hash  or String(tx.hash)

    return tx.hash;

}