import React from 'react';

import { Switch } from "react-router-dom";
import PrivateRoute from '../../routing/PrivateRoute'

import Withdrawal from './Settingz/Withdrawal';
import Invest from './Invest/Invest';
import Dashboard from './Dashboard/Dashboard';


import './Board.css'

export function Board () {

  return (
    <>
    <div className="Board">
      <Switch>

        <PrivateRoute exact path="/dashboard" component={Dashboard} />

        <PrivateRoute exact path="/invest" component={Invest} />

        <PrivateRoute exact path="/withdrawal" component={Withdrawal} />

      </Switch>
    </div>
    </>
  )
}
